import {
  ClientConfig,
  createClient,
  createMicrophoneAndCameraTracks,
} from 'agora-rtc-react'

const config: ClientConfig = {
  mode: 'rtc',
  codec: 'vp8',
}

export const appId: string = '8f9eaf62ba724a05953df9a5e22522ba' //ENTER APP ID HERE
export const token: string =
  '007eJxTYPjD28Oxbuaa8837o5Pq30UfjfTwUg1afNhwxY2vT7qlhXUUGCzSLFMT08yMkhLNjUwSDUwtTY1T0iwTTVONjEyNgKJ35panNgQyMkg02jMzMkAgiM/CkJuYmcfAAACKEB/U'
export const useClient = createClient(config)
export const useMicrophoneAndCameraTracks = createMicrophoneAndCameraTracks()
