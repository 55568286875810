import { appId } from '../utils/agora-wrapper'

export const ChannelForm = (props: {
  setInCall: React.Dispatch<React.SetStateAction<boolean>>
  setChannelName: React.Dispatch<React.SetStateAction<string>>
}) => {
  const { setInCall, setChannelName } = props

  return (
    <form className='join'>
      <button
        onClick={(e) => {
          e.preventDefault()
          setChannelName('main')
          setInCall(true)
        }}
      >
        Join
      </button>
    </form>
  )
}
